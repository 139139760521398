//Default header styles:
header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
  width: 100%;
  height: 175px;
  z-index: 19;
  background-color: $gray-100;
  background-image: url("../../assets/gfx/headerBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  transition-property: width,height,color,border;
  transition-duration: 0.25s;
  .header__logo {
    background-image: url("../../assets/gfx/logo.svg");
    background-size: contain;
    background-repeat: no-repeat !important;
    background-position: 15px 0;
    width: 103px;
    height: 28px;
    @include media-breakpoint-up(sm) {
      width: 170px;
      height: 55px;
    }
  }
}
ul.nav li {
  padding-top: 0.5rem;
  list-style: none;
  a {
    color: $white;
    font-weight: 400;
    text-decoration: none;
    line-height: 1.5;
    &.active::after {
      content: "";
      display: block;
      position: relative;
      bottom: -2px;
      margin: 0 -0.15rem;
      left: 0;
      height: 2px;
      min-width: 100%;
      background-color: $white;
      transition-duration: 0.25s;
    }
    &:hover::after {
      content: "";
      display: block;
      position: relative;
      bottom: -2px;
      margin: 0 -0.15rem;
      left: 0;
      height: 2px;
      min-width: 100%;
      background-color: $white;
      transition-duration: 0.25s;
    }
    &::after {
      content: "";
      display: block;
      position: relative;
      bottom: -2px;
      margin: 0 -0.15rem;
      left: 0;
      height: 2px;
      min-width: 100%;
      background-color: transparent;
      transition-duration: 0.25s;
    }
  }
}

//Header styles when on an utility page:
header.utilityPage {
  color: $primary;
  background-color: #FFF;
  background-image: url("../../assets/gfx/headerBackgroundInverted.svg");
  .header__logo {
    background-image: none;
    mask: url("../../assets/gfx/logo.svg") no-repeat;
    mask-size: contain;
    mask-position: 15px 0;
    
    background-color: $primary;
  }
}

.btn-admin-primary {
  color: #fff;
  background-color: $primaryAdmin;
  border-color: $primaryAdmin;
}
.btn-admin-primary:hover {
  color: #fff;
  background-color: darken(#006974, 4%);
  border-color: darken(#005e67, 3%);
}

.header-side-container {
  @include media-breakpoint-up(lg) {
    max-width: 280px !important;
  }
}