@import '../../assets/scss/variables';

.button__switch {
  display: flex;
  flex-direction: row;
  border: 1px solid darken($buttonGray, 5%);

  border-radius: 1.5rem;
  > button {
    height: 100%;
    background-color: transparent;
    transition: color 100ms;
    &:focus {
      outline: none;
    }
    &:first-child {
      color: black;
      border: 1px solid white;
      border-right: 2px solid white;
    }
    &:nth-child(2) {
      color: black;
      border: 1px solid white;
      border-right: 2px solid white;
    }
    &:last-child {
      color: black;
      border: 1px solid white;
      border-right: 2px solid white;
    }
  }
  .active-left {
    background-color: lighten($buttonGray, 5%);
    color: black;
    border: 1px solid darken($buttonGray, 5%) !important;
    border-right-width: 2px!important;
  }
  .active-middle {
    background-color: lighten($buttonGray, 5%);
    color: black;
    border: 1px solid darken($buttonGray, 5%) !important;
    border-right-width: 2px!important;
  }
  .active-right, &.default-switch .active-left, .active-middle {
    background-color: $buttonPrimaryDark;

    border: 1px solid lighten($buttonPrimaryDark, 5%) !important;
    border-left-width: 2px!important;

    color: white!important;
  }
  
  &.switch-md {
    height: 2.4rem!important;
  }
  &.switch-sm {
    height: 1.8rem!important;
    &>button {
      font-size: 0.75rem!important;
    }
  }
}

@media screen and (max-width: 576px) {
  button.button-action {
      padding: 0!important;
    }
}