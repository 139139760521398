//Method selection
.login__container {
  button.button-login {
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0 0.5rem;
    height: 2.625rem;
    font-size: 0.875rem;
    background-color: transparent;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
    &:hover, &.active {
      color: $primary;
      border-bottom: 2px solid $primary;
      .icon__smart-id {
        background-image: none;
        mask: url('../assets/icons/login/smart-id.svg') no-repeat center/contain;
        background-color: $primary;
      }
      .icon__id-card {
        background-image: none;
        mask: url('../assets/icons/login/id-card.svg') no-repeat center/contain;
        background-color: $primary;
      }
      .icon__credit-card {
        background-image: none;
        mask: url('../assets/icons/login/credit-card.svg') no-repeat center/contain;
        background-color: $primary;
      }
      .icon__mobile-id {
        background-image: none;
        mask: url('../assets/icons/login/mobile-id.svg') no-repeat center/contain;
        background-color: $primary;
      }
    }
  }
  
  //Smart-ID
  .login__smart-id-input {
    margin-bottom: 1.25rem;
    padding: 0 0.75rem;
    border-radius: 2px;
    border: 1px solid $gray-200;
    height: 2.25rem;
  }
  //ID-card
  .login__id-card-input {
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 0.5rem;
    margin-bottom: 1.85rem;
  }
  //Mobile-id
  .mobile-id-input__prefix {
    margin-bottom: 1.25rem;
    padding: 0 0.75rem;
    border-radius: 2px;
    border: 1px solid $gray-200;
    max-width: 60px;
    height: 2.25rem;
    background-color: #F8F8F8;
  }
  .mobile-id-input__main {
    margin-bottom: 1.25rem;
    padding: 0 0.75rem;
    border-radius: 2px;
    border: 1px solid $gray-200;
    width: 160px;
    height: 2.25rem;
  }
}
