.selected-customer {
  &__divider {
      background-color: #F8F8F8;
      height: 2px;
      margin-bottom: 4px;
      margin-top: 4px;
  }

  &__option{
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 2px;

    div:first-child{
      font-weight: 600;
    }
    
    div:nth-child(2){
      font-size: 0.75rem;
      font-weight: 400;
      color: #878787;
    }

    &:hover{
      background-color: rgb(234, 230, 230);
      transition: 300ms;
    }
  }
}