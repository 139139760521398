.agreement-status-change-statuses__wrapper {
  > div:hover {
    cursor: pointer;
    background-color: $gray-200;
    border-radius: $border-radius;
  }
  .selected-card, .selected-card:hover {
    background-color: $gray-200;
    border-radius: $border-radius;
  }
}
.border-radius-right {
  border-top-right-radius: $border-radius!important;
  border-bottom-right-radius: $border-radius!important;
}