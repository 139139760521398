@import 'variables.scss';
button.button-primary {
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
    height: 2.625rem;
    min-width: 10rem;
    border: none;
    border-radius: 1.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: $fontColorBrand;
}
button.button-primary-dark {
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
    height: 2.625rem;
    min-width: 10rem;
    border: none;
    border-radius: 1.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: $fontColorSecondary;
    background-color: $primary;
}


button.button-secondary {
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ;
    border-color: #ffffff;
    border-radius: 1.5rem;
    padding: 0 1.5rem;
    height: 2.625rem;
    min-width: 10rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: $fontColorSecondary;
    background-color: transparent;
}

button.button-simple {
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0 0.5rem;
    height: 2.625rem;
    font-size: 0.875rem;
    background-color: transparent;
    white-space: nowrap;
}

button.button-action {
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  height: 2.1rem;
  min-width: 10rem;
  border: none;
  border-radius: 1.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: $fontColorSecondary;
  background-color: $buttonPrimary;

  &-dark {
    display: flex;
    appearance: none;
    justify-content: center;
    align-items: center;
    border: 1px solid $buttonPrimaryDark;
    border-radius: 11.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: $buttonPrimaryWhite;
    background-color: $buttonPrimaryDark;
    transition: all 250ms;
    padding: 0 1.5rem;
    &:hover {
      background-color: $fontColorSecondary;
      color: $buttonPrimaryDark;
      border: 1px solid $buttonPrimaryDark;
    }
  }
}

.border-radius-circle {
    border-radius: 100%;
}
.border-color-background {
    border-color: $gray-100;
}

.btn-sm {
  font-size: 0.7rem;
  padding: 0 1rem;
  height: 1.7rem;
  min-width: 6rem;
  line-height: 1;
  padding-bottom: 2px;
}

.btn-md {
  font-size: 0.75rem;
  padding: 0 .5rem;
  height: 1.8rem;
  min-width: 8rem;
}

.btn-lg {
  font-size: 0.875rem;
  padding: 0 1.0rem;
  height: 2.1rem;
  min-width: 10rem;
}

button:focus {
    outline-color: $primary;
}
.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.btn-link {
  &.active {
    font-weight: bold;
  }
}
