.icon__logo {
  background: url('../../icons/small-logo.svg') no-repeat center/contain;
  width: 29px;
  height: 22px;

  &--masked {
    mask: url('../../icons/small-logo.svg') no-repeat center/contain;
    background-color: #fff;
    width: 29px;
    height: 22px;
  }
}

.icon__close {
  background: url('../../icons/close.svg') no-repeat center/contain;

  &--masked {
    mask: url('../../icons/close.svg') no-repeat center/contain;
    background-color: #fff;
  }
}

.icon__info {
  display: inline-block;
  width: 18px;
  height: 18px;

  background: url('../../icons/info.svg') no-repeat center/contain;

  &--masked {
    mask: url('../../icons/info.svg') no-repeat center/contain;
    background-color: #fff;
  }
}
