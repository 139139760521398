.file-preview{
  height: 300px;
  border-radius: .5rem;
  background-color: $gray-100;
  border: 1px solid $gray-200;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  object-fit: contain;

  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}