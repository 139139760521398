.invoice-group-form {
  &__metering-point{
    background-color: #F8F8F8;
    border-radius: 5px;
  }

  &__metering-point-select{
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 2px;

    &--disabled {
      pointer-events: none;

      div:first-child{
       opacity: 0.5;
      }
    }
    
    div:not(:first-child) {
      font-size: 0.75rem;
      font-weight: 400;
      color: #878787;
    }

    &:hover{
      background-color: rgb(234, 230, 230);
      transition: 300ms;
    }
  }
}