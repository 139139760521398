.icon--8 {
  width: 0.5rem;
  height: 0.5rem;
}
.icon--16 {
  width: 1rem;
  height: 1rem;
}
.icon--24 {
  width: 1.5rem;
  height: 1.5rem;
}
.icon--30 {
  width: 30px;
  height: 30px;
}
.icon--36 {
  width: 36px;
  height: 36px;
}
.icon--42 {
  width: 42px;
  height: 42px;
}
.icon--50 {
  width: 50px;
  height: 50px;
}
