//Footer default styles:
footer {
    background-color: $gray-100;
    transition-duration: 0.25s;
    .footer__illustration {
        position: relative;
        top: 38px;
        width: 100%;
        height: 138px;
        max-height: 138px;
        background-image: url('../../assets/gfx/footerDecoration.png');
        background-size: cover;
        background-position-x: right;
        @include media-breakpoint-up(sm) {
            background-position-x: center;
        }
        background-position-y: center;
        opacity: 0.6;
    }
    .footer__container {
        display: flex;
        justify-content: space-between;
        padding: 1.75rem 0;
        @include media-breakpoint-up(sm) {
            padding: 1.75rem 3rem;
        }
        height: 158px;
        background-image: url("../../assets/gfx/footerBackground.svg");
        background-size: cover;
        background-position-x: center;
        background-position-y: top;
    }
}
.bottom__decoration {
    display: block;
    position: relative;
    top: 0;
    width: 100%;
    background-color: $white;
    visibility: visible;
}

//Footer styles when on a utility page:
footer.utilityPage {
    background-color: $white;
    .footer__container {
        background-color: $white;
        background-image: url("../../assets/gfx/footerBackgroundInverted.svg");
    }
    svg.bottom__decoration path {
        fill: $white;
        stroke: $white;
    }
}
