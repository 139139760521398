@import '../../assets/scss/variables.scss';

.card-body {
  .customer-status__select > div {
    border: 2px solid $primaryGray;
  }

  .customer-contact-persons__search-row-buttons {
    display: flex;
    margin-left: auto;
    button {
      margin-left: .5rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

table.customer-contact-persons__table {
  width: 100%;
  border-collapse: collapse;
  line-height: 2.75;

  th {
    padding: 0 0.75rem;
    width: auto;
    text-transform: uppercase;
    text-align: left;
    font-size: 0.75rem;
    font-weight: 400;
    color: #878787;
    border-bottom: 1px solid #E7E7E7;
  }
  th:last-of-type {
    text-align: center;
  }

  tr.selected-row {
    background-color: $gray-400;
  }

  tbody tr {
    border-bottom: 1px solid #E7E7E7;

    td {
      padding: 0.1rem 0.75rem;
      font-weight: 300;
      font-size: 0.875rem;
    }
    td:last-of-type {
      text-align: center;
    }
  }

  tfoot tr {
    border-top: 2px solid #E7E7E7;

    td {
      padding: 0 0.75rem;
      font-weight: 300;
      font-size: 0.875rem;
    }
  }
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  height: 20px;
  width: 90px;
  font-size: 0.75rem;

  &--paid {
    background-color: $fontColorHelpingLight;
  }

  &--unpaid {
    background-color: $fontColorWarning;
    color: white;
  }
}

.pagination-wrapper {
  display: flex;
}
