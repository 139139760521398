@import "assets/scss/bootstrap_imports";

//Variables, utilities and other commonly used styles
@import 'assets/scss/variables';
@import 'assets/scss/typography';
@import 'assets/scss/button';
@import 'assets/scss/spacings';
@import 'assets/scss/icons';
@import 'assets/scss/icons/icons';
@import 'assets/scss/table';
@import 'assets/scss/modals';
@import 'assets/scss/forms';

//3rd party related styles:
@import 'assets/scss/plugins/react-select';
@import 'assets/scss/plugins/react-date-picker';
@import 'assets/scss/plugins/react-pdf';
@import "assets/scss/booststrap_overrides";

//Components
@import 'components/Footer/Footer';
@import 'components/Header/Header';
@import 'components/CustomerContactPersons/CustomerContactPersons__search-bar';
@import 'components/PetrolStations/PetrolStations__table';
@import 'components/Tanks/Tanks__search-bar';
@import 'components/TankProgressBar/Tank__progress-bar';
@import 'components/Agreements/Agreements__search-bar';
@import 'components/Users/Users__search-bar';
@import 'common/table/paginator';
@import 'components/MobileMenu/MobileMenu';
@import 'components/SwitchButton/SwitchButton';
@import 'components/Agreements/modals/AgreementStatusChangeModal';
@import 'components/ElectricityAgreements/components/NewElectricityAgreement';
@import 'components/ElectricityInvoices/modals/ElectricityAgreementInvoiceModal';
@import 'components/ElectricityProductionTables/modals/ElectricityProductionTableModal';
@import 'components/ElectricityInvoices/modals/CreateInvoicesModal';
@import 'components/LoyaltyCampaigns/modals/UpdateLoyaltyCampaignModal';
@import 'components/FileUpload/FileDropzone';
@import 'components/FileUpload/FileComponent';
@import 'components/FileUpload/FilePreview';
@import 'components/ElectricityInvoiceGroups/components/InvoiceGroup';
@import 'components/ElectrcityCustomers/components/ElectricityCustomerSettingsModal/InvoiceGroupForm';
@import 'components/ElectricityInvoices/ElectricityInvoicesSearchBar';
@import 'components/Users/modals/AddUsersModal';
@import 'components/ChargingAgreements/components/NewChargingAgreement/NewChargingAgreementStationsForm';

//Pages
@import 'pages/Frontpage';
@import 'pages/Login';

input:focus {
  outline-color: $primary;
}

.cursor-pointer-hover {
  cursor: pointer;
}

.fade.modal-backdrop.show,
.modal.show {
  z-index: 1041 !important;
}

.fade.modal-backdrop.show:last-of-type,
.modal.show:last-of-type {
  z-index: 1042 !important;
}

main {
  display: flex;
  position: relative;
  justify-content: center;
  padding: 6rem 3rem 0;
  min-height: calc(100vh - 464px);
  width: 100%;
  background-color: $gray-100;
  transition-duration: 0.25s;

  &.utilityPage {
    transition-duration: 0.05s;
    background-color: #ffffff;
  }
}

.modal-content {
  background-color: #fff;
  padding: 12px 24px;
}

.pac-container {
  z-index: 1400;
}