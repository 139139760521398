.login-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
  width: 1.5rem;
  height: 1.5rem;
}
.icon__smart-id    { background: url('../../icons/login/smart-id.svg') no-repeat center/contain; }
.icon__id-card     { background: url('../../icons/login/id-card.svg') no-repeat center/contain; }
.icon__credit-card { background: url('../../icons/login/credit-card.svg') no-repeat center/contain; }
.icon__mobile-id   { background: url('../../icons/login/mobile-id.svg') no-repeat center/contain; }
.icon__mobile-menu { background: url('../../icons/login/mobile-menu.svg') no-repeat center/contain; }
.icon__switch      { background: url('../../icons/login/switch.svg') no-repeat center/contain; }
.icon__profile     { background: url('../../icons/login/profile.svg') no-repeat center/contain; }
.icon__log-out     { background: url('../../icons/login/log-out.svg') no-repeat center/contain; }

.icon__external-link {
  background: url('../../icons/header/external-link.svg') no-repeat center/contain;
}
.icon__bell {
  mask: url('../../icons/header/bell.svg') no-repeat center/contain;
  background-color: #fff;
}

