.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.mr-4 {
    margin-right: 1.5rem;
}

.pr-4 {
    padding-right: 1.5rem;
}

.w-6-rem {
    min-width: 6rem;
}
.w-7-rem {
    width: 7rem;
}
.w-8-rem {
    min-width: 8rem;
}
.w-13-rem {
    min-width: 13rem;
}
.w-10-rem {
    min-width: 10rem;
}
.mw-160-px {
    min-width: 10rem;
}
.mw-192-px {
    min-width: 12rem;
}
.bar-position {
    margin-top: -11rem;
}
.mw-card-nr {
    min-width: 10.5rem;
}
.mw-192-px {
    min-width: 12rem;
}
.mw-phone-input {
    min-width: 224px;
}

.line-height-1 {
    line-height: 1;
}