.new-electricity-agreement__metering-point-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
    thead {
        th {
            padding: 0.75rem 1.25rem;
            font-weight: normal;
            color: $dark;
        }
    }
    tbody {
        td {
            position: relative;
            padding: 1.25rem;
            color: $dark;
            font-weight: 600;
            background-color: $gray-100;

            &:first-child {
                width: 48px;
            }

            &:nth-child(2) {
                max-width: 138px;
                &::after {
                    content: '|';
                    position: absolute;
                    right: 0;

                    font-size: 1rem;
                    line-height: 1.25;
                    color: $gray-500;
                }
            }
        }
    }
}