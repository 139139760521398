// TODO adjust to one used already in customerCards search bar

.select {
  .select__control {
    &--is-focused {
      box-shadow: none;
      .select__value-container {
        .select__placeholder {
          color: $fontColorGrayDark !important;
        }
        .select__single-value {
          color: $fontColorGrayDark !important;
        }
      }
    }
    .select__value-container {

      .select__placeholder {
        font-size: 0.75rem;
        color: $fontColorGrayDark;
        text-transform: uppercase;
      }
      .select__single-value {
        font-size: 0.75rem;
        color: $fontColorGrayDark;
        text-transform: uppercase;
      }
    }

    .select__indicators {
      .select__indicator-separator {
        display: none;
      }
    }

  }
  .select__menu {
    .select__menu-list {
      padding: 0;
      .select__option {
        font-size: 0.75rem;
        color: $fontColorGrayDark;
        text-transform: uppercase;
        background-color: #fff;
        &:hover {
          background-color: $primaryGray;
          color: $fontColorHelpingDark !important;
        }
        &--is-selected {
          background-color: $primaryGray;
          color: $fontColorHelpingDark !important;
          opacity: 0.9;
          &:hover {
            background-color: $primaryGray;
            color: $fontColorHelpingDark !important;
          }
        }
      }
    }
  }
}
