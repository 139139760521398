.form-select {
    padding: 0 !important;
    border: none;
}

.form-async-select {
    &--invalid{
        span{
          color: $danger;
          font-size: 0.75rem;
        }
      
        .css-yk16xz-control{
          border-color: $danger;
        }
    }
}

.was-validated {
    .form-select.is-invalid {
        padding: 0 !important;
        > div[class*="control"]  {
            padding-right: calc(1.5em + 1rem) !important;
            background-color: transparent;
            border-color: $danger;
        }
    }
}