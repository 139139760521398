.loyalty-campaign__icon_dropdown{
  &--invalid {
    margin-top: 0.25rem;
    color: $danger;
    text-align: center;
    font-size: 80%;
  }
}

.campaign-icon {
  margin: 0 0.5rem 0.5rem;
  border-radius: 50%;
  border: 2px solid $primaryAdmin;
  min-height: 42px;
  width: 65px;
  height: 65px;
  transition: all .1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
      cursor: pointer;
      transform: scale(0.9);
  }

  &--active {
    border-color: $primary;
  }
}