.icon__delete {
  background-image: url('../../icons/buttons/delete.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
  width: 1.5rem;
  height: 1.5rem;
  &--masked {
    mask: url('../../icons/buttons/delete.svg') no-repeat center/contain;
    background-color: #fff;
    display: none;
  }
  &--outline-mask {
    mask: url('../../icons/buttons/delete.svg') no-repeat center/contain;
    background-color: $black;
  }
}

.icon__preview-file {
  background-image: url('../../icons/buttons/preview-file.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
  width: 1.5rem;
  height: 1.5rem;
}

.icon__download {
  background-image: url('../../icons/buttons/download.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
  width: 1.5rem;
  height: 1.5rem;
}
.icon__cancel {
  background-image: url('../../icons/buttons/cancel.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
  width: 1.5rem;
  height: 1.5rem;
  &--masked {
    mask: url('../../icons/buttons/cancel.svg') no-repeat center/contain;
    background-color: #fff;
    display: none;
  }
  &--outline-mask {
    mask: url('../../icons/buttons/cancel.svg') no-repeat center/contain;
    background-color: $black;
  }
}
.icon__file {
  background-image: url('../../icons/buttons/file.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
  width: 1.5rem;
  height: 1.5rem;
}


.icon__edit {
  background-image: url('../../icons/buttons/edit.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
  width: 1.5rem;
  height: 1.5rem;
  &--masked {
    mask: url('../../icons/buttons/edit.svg') no-repeat center/contain;
    background-color: #fff;
    display: none;
  }
  &--outline-mask {
    mask: url('../../icons/buttons/edit.svg') no-repeat center/contain;
    background-color: $black;
  }
}

.icon__add  { background: url('../../icons/buttons/plus.svg') no-repeat center/contain; 
  
  &--primary {
    mask: url('../../icons/buttons/plus.svg') no-repeat center/contain;
    background-color: $primary;}
}

.icon__remove  { background: url('../../icons/buttons/minus.svg') no-repeat center/contain; 
  
  &--primary {
    mask: url('../../icons/buttons/minus.svg') no-repeat center/contain;
    background-color: $primary;
  }
}


.btn-outline-icon {
  max-width: 1.8rem;
  min-width: 1.8rem;
  height: 1.8rem;
  padding: .3rem;
}

.btn.btn-outline-icon:hover {
  i.icon__edit, i.icon__delete {
    &--outline-mask {
      background-color: $white;
    }
  }
}
