@import '../../assets/scss/variables.scss';

table.users__table {
  width: 100%;
  border-collapse: collapse;
  line-height: 2.75;

  th {
    padding: 0 0.75rem;
    width: auto;
    text-transform: uppercase;
    text-align: start;
    font-size: 0.75rem;
    font-weight: 400;
    color: #878787;
    border-bottom: 1px solid #E7E7E7;
  }
  th:last-of-type {
    text-align: center;
  }


  tbody tr {
    border-bottom: 1px solid #E7E7E7;

    .under-threshold {
      color: $fontColorWarning;
    }
    .over-threshold {
      color: $fontColorSuccess;
    }
    td {
      padding: 0.1rem 0.75rem;
      font-weight: 300;
      font-size: 0.875rem;
      text-align: start;
    }
    td:last-of-type {
      text-align: start;
    }

  }

  tfoot tr {
    border-top: 2px solid #E7E7E7;

    td {
      padding: 0 0.75rem;
      font-weight: 300;
      font-size: 0.875rem;
    }
  }

  .users__status {
    .disabled-access-status,
    .active-access-status {
      border-radius: 1.5rem;
      height: 1.8rem;
      appearance: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 .1rem;
      font-size: 0.875rem;
      font-weight: 500;
    }
    .disabled-access-status {
      background-color: $fontColorGrayDark;
      color: white;
    }
    .active-access-status {
      color: $fontColorSuccess;
      background-color: $primaryGray;
      font-weight: 400;
    }
  }
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  height: 20px;
  width: 90px;
  font-size: 0.75rem;

  &--paid {
    background-color: $fontColorHelpingLight;
  }

  &--unpaid {
    background-color: $fontColorWarning;
    color: white;
  }
}

.pagination-wrapper {
  display: flex;
}
