.icon__sort-down-up {
  background: url('../../icons/sorting/arrow-down-up.svg') no-repeat center/contain;
  &--hover {
    opacity: 0;
    background: url('../../icons/sorting/arrow-down-up.svg') no-repeat center/contain;
  }
}
.icon__sort-ascending {
  background: url('../../icons/sorting/sort-ascending.svg') no-repeat center/contain;
}

.icon__sort-descending {
  background: url('../../icons/sorting/sort-descending.svg') no-repeat center/contain;
}

.sort-down-up-hover {
  cursor: pointer;
  &:hover {
    .icon__sort-down-up--hover {
      opacity: 1;
    }
  }
}

@media handheld {
  .icon__sort-down-up--hover {
    display: none;
  }
}