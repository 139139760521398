.customer-invoice-groups{
  .customer-invoice-groups__divider{
    background-color: #F8F8F8;
    height: 2px;
  }
}

.invoice-group{
  h2{
    font-size: 1.5rem;
  }

  .invoice-group__metering-point{
    background-color: #F8F8F8;
    border-radius: 5px;
  }
}