.file-upload-form {
  height: 300px;
  border-radius: .5rem;
  background-color: $gray-100;
  border: 1px solid $gray-200;
  cursor: pointer;

  .text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    :last-child{
      color: $primary;
    }
  } 

  &--invalid {
    border-color: $danger;
  } 
}