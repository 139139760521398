.icon__logo {
    display: inline;
    content: url('../icons/small-logo.svg');
    width: 29px;
    height: 22px;
}

.icon__close {
    display: inline;
    content: url('../icons/close.svg');
    width: 9px;
    height: 9px;
}

.icon__close-light {
    display: inline;
    content: url('../icons/close-light.svg');
    width: 9px;
    height: 9px;
}

.icon__always-open {
    display: inline;
    content: url('../icons/always-open.svg');
    margin: 0 0.5rem;
    width: 42px;
    height: 42px;
}

.icon__adblue {
    display: inline;
    content: url('../icons/adblue.svg');
    margin: 0 0.5rem;
    width: 42px;
    height: 42px;
}

.icon__invoice {
    display: inline;
    content: url('../icons/invoice.svg');    
    margin: 0 0.5rem;
    width: 42px;
    height: 42px;
}

.icon__settings {
    display: inline;
    content: url('../icons/settings.svg');    
    margin: 0 0.5rem;
    width: 42px;
    height: 42px;
}

.icon__table {
    display: inline;
    content: url('../icons/table.svg');    
    margin: 0 0.5rem;
    width: 42px;
    height: 42px;
}

.icon__car-wash {
    display: inline;
    content: url('../icons/car-wash.svg');
    margin: 0 0.5rem;
    width: 42px;
    height: 42px;
}
.icon__delete {
    background-image: url('../icons/buttons/delete.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1.5rem;
    height: 1.5rem;
}
.icon__remove-user {
    background-image: url('../icons/remove-user.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1.5rem;
    height: 1.5rem;
}
.icon__download {
    background-image: url('../icons/buttons/download.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1.5rem;
    height: 1.5rem;
}
.icon__upload {
    background-image: url('../icons/buttons/upload.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1.5rem;
    height: 1.5rem;
}
.icon__file {
    background-image: url('../icons/buttons/file.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1.5rem;
    height: 1.5rem;
}
.icon__img-file {
    background-image: url('../icons/buttons/img-file.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1.2rem;
    height: 1.2rem;
}
.icon__edit {
    background-image: url('../icons/buttons/edit.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1.5rem;
    height: 1.5rem;
}
.icon__login {
    background-image: url('../icons/buttons/login.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1.5rem;
    height: 1.5rem;
}
.icon__refresh {
    background-image: url('../icons/buttons/refresh.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1.5rem;
    height: 1.5rem;
}
.icon__refresh-white {
    background-image: url('../icons/buttons/refresh-white.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1rem;
    height: 1rem;
}

.icon__search {
    background-image: url('../icons/buttons/search.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    width: 1rem;
    height: 1rem;
}

@mixin loyalty-dynamic-icon {
    width: 42px;
    background-color: $primaryAdmin;

    &--active{
        background-color: $primary;
    }
}

.icon__burger {
    mask: url('../icons/campaign/burger.svg') no-repeat center/contain;    

    @include loyalty-dynamic-icon;
}

.icon__coffee {
    mask: url('../icons/campaign/coffee.svg') no-repeat center/contain;    

    @include loyalty-dynamic-icon;
}
.icon__hot-dog {
    mask: url('../icons/campaign/hot-dog.svg') no-repeat center/contain;    

    @include loyalty-dynamic-icon;
}

.icon__star {
    mask: url('../icons/campaign/star.svg') no-repeat center/contain;    

    @include loyalty-dynamic-icon;
}