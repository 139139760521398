.card {
  min-height: 80px;
}
.card-body {
  .search-bar__input {
    border-radius: 4px;
    border: 2px solid $primaryGray;
    min-width: 225px;
    padding: .1rem .5rem;
    margin-right: .5rem;
    font-weight: 300;
    font-size: 0.875rem;
  }
}

.form-check-label-black {
  .form-check-input:valid ~ .form-check-label {
    color: $black !important;
  }
  .form-check-input:invalid ~ .form-check-label {
    color: $black !important;
  }
}

.modal-sm {
  max-width: 400px;
}
