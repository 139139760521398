main {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0rem 6rem;
}

.frontpage__left-half {
    width: 100%;
    max-width: 510px;
}