// Bootstrap table overwrites
.table {
  thead th {
    border-top: none;
    border-bottom: none;
    color: $gray-600;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    text-transform: uppercase;
  }
  tbody td {
    font-size: 0.875rem;;
  }
  td, th {
    vertical-align: middle;
  }
}

table.generic-table {
  width: 100%;
  border-collapse: collapse;
  line-height: 2.75;

  th {
    padding: 0 0.75rem;
    width: auto;
    text-transform: uppercase;
    text-align: left;
    font-size: 0.75rem;
    font-weight: 400;
    color: #878787;
    border-bottom: 1px solid #E7E7E7;
  }
  th:last-of-type {
    text-align: center;
  }

  tbody tr {
    border-bottom: 1px solid #E7E7E7;

    td {
      padding: 0.1rem 0.75rem;
      font-weight: 300;
      font-size: 0.875rem;
    }
  }

  tfoot tr {
    border-top: 2px solid #E7E7E7;

    td {
      padding: 0 0.75rem;
      font-weight: 300;
      font-size: 0.875rem;
    }
  }

  tbody{
    .sub-menu{
      width: 100%;
      font-size: 0.75rem;
      position: relative;

      &:hover{
        background-color: transparent;
      }

      .sub-menu__container{
        max-height: 150px;
        overflow-y: auto;
      }

      .sub-menu__metering-point {
        font-size: 0.75rem;

        :first-child{
          width: 25%;
        }

        :nth-child(2){
          width: 60%;
        }
      }
    }
  }
}

.table-borderless {
  thead th, tbody td {
    padding: 0.25rem;
    @include media-breakpoint-up(sm) {
      padding: 0.5rem 1rem 1rem 1rem;
    }
  }
}

  .row__actions {
    display: flex;
    justify-content: space-evenly;
    .action-icon {
      height: 16px;
      &:hover {
        cursor: pointer;
      }
    }
  }