@import "../../assets/scss/variables";

@mixin disabled-item() {
  cursor: not-allowed;

  &:hover {
    border-color: #d9d9d9;

    a {
      color: #d9d9d9;
    }
  }
}

.pagination {
  font-size: 12px;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  user-select: none;
  padding: 0;
  padding-top: 1rem;
  margin: auto;

  > li {
    list-style: none;
  }

  &-total-text {
    float: left;
    height: 30px;
    line-height: 30px;
    list-style: none;
    padding: 0;
    margin: 0 8px 0 0;
  }

  &:after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }

  &-item {
    cursor: pointer;
    border-radius: 6px;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    list-style: none;
    float: left;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    margin-right: 8px;

    a {
      text-decoration: none;
      color: #666;
    }

    &:hover {
      border-color: #008C9A;

      a {
        color: #008C9A;
      }
    }

    &-disabled {
      @include disabled-item();
    }

    &-active {
      background-color: #008c9a;
      border-color: #008c9a;

      a {
        color: #fff;
      }

      &:hover {
        a {
          color: #fff;
        }
      }
    }
  }

  &-jump-prev,
  &-jump-next {
    &:after {
      content: '•••';
      display: block;
      letter-spacing: 2px;
      color: #ccc;
      font-size: 12px;
      margin-top: 1px;
    }

    &:hover {
      &:after {
        color: #008c9a;
      }
    }
  }

  &-jump-prev {
    //&:hover {
    //  &:after {
    //    content: '«';
    //  }
    //}
  }

  &-jump-next {
    //&:hover {
    //  &:after {
    //    content: '»';
    //  }
    //}
  }

  &-jump-prev-custom-icon,
  &-jump-next-custom-icon {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transition: all 0.2s;

      content: '•••';
      opacity: 1;
      display: block;
      letter-spacing: 2px;
      color: #ccc;
      font-size: 12px;
      margin-top: 1px;
    }

    .custom-icon-jump-prev,
    .custom-icon-jump-next {
      opacity: 0;
      transition: all 0.2s;
    }

    &:hover {
      &:after {
        opacity: 0;
        color: #ccc;
      }

      .custom-icon-jump-prev,
      .custom-icon-jump-next {
        opacity: 1;
        color: #008c9a;
      }
    }
  }

  &-prev,
  &-jump-prev,
  &-jump-next {
    margin-right: 8px;
  }

  &-prev,
  &-next,
  &-jump-prev,
  &-jump-next {
    cursor: pointer;
    color: #666;
    font-size: 10px;
    border-radius: 6px;
    list-style: none;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    float: left;
    text-align: center;
  }

  &-prev {
    a {
      &:after {
        content: '‹';
        display: block;
      }
    }
  }

  &-next {
    a {
      &:after {
        content: '›';
        display: block;
      }
    }
  }

  &-prev,
  &-next {
    border: 1px solid #d9d9d9;
    font-size: 18px;

    a {
      color: #666;

      &:after {
        margin-top: -1px;
      }
    }
  }

  &-disabled {
    cursor: not-allowed;

    a {
      color: #ccc;
    }

    .pagination-item,
    .pagination-prev,
    .pagination-next {
      @include disabled-item();
    }

    .pagination-jump-prev,
    .pagination-jump-next {
      pointer-events: none;
    }
  }

  &-options {
    float: left;
    margin-left: 15px;

    &-size-changer {
      float: left;
      width: 80px;
    }

    &-quick-jumper {
      float: left;
      margin-left: 16px;
      height: 28px;
      line-height: 28px;

      input {
        margin: 0 8px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #d9d9d9;
        outline: none;
        padding: 3px 12px;
        width: 50px;
        height: 28px;

        &:hover {
          border-color: #008c9a;
        }
      }

      button {
        display: inline-block;
        margin: 0 8px;
        font-weight: 500;
        text-align: center;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 0 15px;
        font-size: 12px;
        border-radius: 6px;
        height: 28px;
        user-select: none;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        position: relative;
        color: rgba(0, 0, 0, 0.65);
        background-color: #fff;
        border-color: #d9d9d9;

        &:hover,
        &:active,
        &:focus {
          color: #008c9a;
          background-color: #fff;
          border-color: #008c9a;
        }
      }
    }
  }

  &-simple {
    .pagination-prev,
    .pagination-next {
      border: none;
      height: 24px;
      line-height: 24px;
      margin: 0;
      font-size: 18px;
    }

    .pagination-simple-pager {
      float: left;
      margin-right: 8px;
      list-style: none;

      .pagination-slash {
        margin: 0 10px;
      }

      input {
        margin: 0 8px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #d9d9d9;
        outline: none;
        padding: 5px 8px;
        min-height: 20px;

        &:hover {
          border-color: #008c9a;
        }
      }

      button {
        display: inline-block;
        margin: 0 8px;
        font-weight: 500;
        text-align: center;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 0 8px;
        font-size: 12px;
        border-radius: 6px;
        height: 26px;
        user-select: none;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        position: relative;
        color: rgba(0, 0, 0, 0.65);
        background-color: #fff;
        border-color: #d9d9d9;

        &:hover,
        &:active,
        &:focus {
          color: #008c9a;
          background-color: #fff;
          border-color: #008c9a;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .pagination-item {
    &-after-jump-prev,
    &-before-jump-next {
      display: none;
    }
  }
}