@font-face {
  font-family: JanoSans Pro;
  src: url('../fonts/JanoSansProRegular.woff');
  font-weight: 400;
}
@font-face {
  font-family: JanoSans Pro;
  src: url('../fonts/JanoSansProBold.woff');
  font-weight: bold;
}
@font-face {
  font-family: JanoSans Pro;
  src: url('../fonts/JanoSansProLight.woff');
  font-weight: 300;
}

h1 {
  margin: 0 0 0.5rem;
  font-size: 1.125rem;
  font-family: JanoSans Pro, sans-serif;
  font-weight: 400;
  color: $fontColorSecondary;
}

h2 {
  margin: 0 0 0.75rem;
  font-size: 1rem;
  font-family: JanoSans Pro, sans-serif;
  font-weight: 400;
  color: $fontColorHelpingDark;
}

h3 {
  margin: 0 0 0.25rem;
  font-size: 1.025rem;
  color: $fontColorBrand;
}

h4 {
  margin: 0 0 0.20rem;
  font-size: 0.9rem;
  color: $fontColorBrand;
}

h5 {
  font-weight: 400;
}

.line-height-1 {
  line-height: 1;
}

p {
  margin: 0;
}

strong {
  font-weight: 500;
}