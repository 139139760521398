.modal-dialog {
  @include media-breakpoint-down(md) {
    max-width: 90%;
  }
  @include media-breakpoint-down(xs) {
    max-width: 100%;
  }

  &.bg-light {
    .modal-content {
      background-color: $light;
    }
  }
}

.modal-content {
  background-color: white;
  padding: 12px 24px;
  border: none;
  box-shadow: 0px 0px 16px rgba(23, 33, 52, 0.17)
}
.modal-header {
  border-bottom: none;
  .close {
    padding: 0.5rem 0;
    font-size: 38px;
    font-weight: 200;
    line-height: 30px;
    outline: none;
  }

  .modal-title {
    font-size: 2rem;
    font-family: JanoSans Pro;
  }
}

.pdf-preview-modal {
  &__container {
    height: 900px;
    text-align: center;
    overflow: auto;
  }

  .modal-content {
      border-radius: 0;
      background-color: $primaryGray;
      padding: 0;

      .modal-header {
          background-color: #ffffff;
          display: flex;
          align-items: center;
          padding: 1rem 2rem;
          border-radius: 0;

          .close {
            margin-right: 1rem;
          }

          i {
            width: 1.2rem;
            height: 1.2rem;
          }
      }
  }
}