@import '../../assets/scss/variables.scss';

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  height: 20px;
  width: 90px;
  font-size: 0.75rem;

  &--paid {
    background-color: $fontColorHelpingLight;
  }

  &--unpaid {
    background-color: $fontColorWarning;
    color: white;
  }
}

.pagination-wrapper {
  display: flex;
}
