$primary: #008C9A;
$primaryAdmin: #293846;
$primaryGray: #EDEDED;

$fontColorSecondary: #ffffff;
$fontColorBrand: #008C9A;
$fontColorHelpingDark: #293846;
$fontColorHelpingLight: #E4E5E7;
$fontColorWarning: #FB1E6E;
$fontColorGray: #E2E2E2;
$fontColorGrayDark: #959595;

$fontColorSuccess: #008000;

$buttonPrimary: $primary;
$buttonPrimaryWhite: #ffffff;
$buttonPrimaryDark: darken($primary, 15%);
$buttonGray: $primaryGray;
$buttonLightGray: lighten($buttonGray, 5%);
