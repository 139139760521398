.react-date-picker {
  background: $input-bg;
  height: $input-height;

  &__wrapper {
    border-color: $border-color;
    border-width: $border-width;
    border-radius: $border-radius;
  }
  &__inputGroup {
    margin-left: 0.6rem;
    min-width: 10rem
  }

  &__inputGroup__leadingZero {
    margin-right: -1px
  }
  &__inputGroup__divider {
    padding: 1px 2px 0 0
  }

  &__button {
    &:hover, &:focus {
      >svg path {
        fill: $primary!important;
      }
    }
  }

  .react-calendar {
    border-radius: $border-radius;
    border-color: darken($border-color, 2%);
    &__tile:hover {
      background-color: transparentize($primary, 0.2);
    }
    &__tile--now {
      background-color: transparentize($primary, 0.7);
      &:hover {
        background-color: transparentize($primary, 0.2);
        color: $white;
      }
    }
    &__tile--active {
      background-color: transparentize($primary, 0.5);
    }
    &__month-view {
      &__weekdays {
        &__weekday {
          >abbr {
            text-decoration: underline;
            cursor:initial;
          }
        }
      }
    }
  }
}

.react-date-picker.invalid {
  .react-date-picker {
    &__wrapper {
      border-color: $form-feedback-invalid-color;
    }

    &__inputGroup {
      padding-right: calc(1.5em + 0.9rem);
      background-image: escape-svg($form-feedback-icon-invalid);
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.10rem) center;
      background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);

    }
  }
}
