.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  color: $primary;
  visibility: hidden;
  opacity: 0;
  transition-duration: 0.25s;
  z-index: 9000;
  .btn {
    color: $primary !important;
  }
  &.open {
    visibility: visible;
    opacity: 1;
  }
  .icon__close--masked {
    width: 1rem;
    height: 1rem;
    background-color: $primary;
  }
  ul.nav li {
    width: fit-content;
    a {
      color: $primary;
    }
    a::after {
      background-color: $white;
    }
    a:hover::after {
      background-color: $primary;
    }
  }
}

.mobile-menu__footer-illustration {
  position: relative;
  top: 26px;
  width: 100%;
  height: 138px;
  max-height: 138px;
  background-image: url('../../assets/gfx/footerDecoration.png');
  background-size: cover;
  background-position-y: center;
  background-position-x: right;
  @include media-breakpoint-up(sm) {
    background-position-x: center;
  }
  opacity: 0.6;
}
.mobile-menu__footer {
  height: 100%;
  background-color: $secondary;
}
