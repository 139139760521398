.icon__logo {
    display: inline;
    content: url('../../icons/small-logo.svg');
    width: 29px;
    height: 22px;
}

.icon__close {
    display: inline;
    content: url('../../icons/close.svg');
    width: 9px;
    height: 9px;
}

@import "icons__general";
@import "icons__actions";
@import "icons__login";
@import "icons__sizes";
@import "icons__sorting";