.file-component__file {
  border: 1px solid hsl(0,0%,80%);
  border-radius: 4px;
  padding: 8px;

  .seperator{
    width: 1px;
    background-color: hsl(0,0%,80%);
  }

  :last-child{
    i {
      width: 0.8rem;
      height: 0.8rem;
      cursor: pointer;
    }
  }
}